import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import "@/utils/rem.js"

import FastClick from 'fastclick'
import Storage from 'vue-ls'
import Viewer from 'v-viewer'
import * as customFilter from '@/utils/filters'

import '@/utils/vee-validate'
import '@/core/components'
import 'mand-mobile/components/_style/global.styl'
import 'normalize.css'
import './styles/index.styl'
import 'viewerjs/dist/viewer.css'

Vue.use(Viewer, {
  defaultOptions: {
    toolbar: false,
    title: false,
  },
})

Vue.config.productionTip = false

FastClick.attach(document.body)

const options = {
  namespace: 'app__', // key键前缀
  name: 'ls', // 命名Vue变量.[ls]或this.[$ls],
  storage: 'local', // 存储名称: session, local, memory
}

Vue.use(Storage, options)

/**
 * @desc 全局注册过滤器
 */
Object.keys(customFilter).forEach(key => {
  Vue.filter(key, customFilter[key])
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
